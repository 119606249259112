import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";

import avatar from "assets/img/logo-big.jpg";

import { authActions } from '../../actions';

const LoginPage = ({ history, login, needMFA, error }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  useEffect(() => {
    document.body.classList.add("login-page");

    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  useEffect(() => {
    setUsername('');
    setPassword('');
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username && password) {
      login(username, password, null);
    }
  };

  if (username && password && needMFA) {
    history.replace({
      pathname: '/auth/mfa-token-login',
      state: { username, password }
    });
  }

  const badCredentialsError = () => {
    let errorLoginTxt = '';
    if (error && (error.toString().includes("bad-credentials") || error.toString().includes('INVALID_CREDENTIALS'))) {
      errorLoginTxt = 'Invalid Username/Password';
    } else if (error && error.toString().includes("USER_BLOCKED")) {
      errorLoginTxt = 'User is blocked. Please contact your administrator.';
    } else {
      errorLoginTxt = '';
    }

    if (error) {
      return (
        <p className="login-error" data-testid="login-error">
          { errorLoginTxt }
        </p>
      );
    }
  };

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form onSubmit={handleSubmit}>
                <Card className="card-lock text-center">
                    <CardHeader>
                      <img src={avatar} alt="avatar-img" style={{ backgroundColor: 'white', borderRadius: 35 }} />
                    </CardHeader>
                    <CardBody>
                      <div className="text-left">
                        <label htmlFor="username">Username</label>
                        <InputGroup className={"no-border form-control-lg " + (usernameFocus ? "input-group-focus" : "")}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="username"
                            data-testid="username-input"
                            name="username"
                            type="text"
                            placeholder="Username"
                            className="placeholder:text-[#767676] placeholder:opacity-100"
                            value={username}
                            onChange={handleChange}
                            onFocus={() => setUsernameFocus(true)}
                            onBlur={() => setUsernameFocus(false)}
                          />
                        </InputGroup>
                      </div>
                      <div className="text-left">
                        <label htmlFor="password">Password</label>
                        <InputGroup className={"no-border form-control-lg " + (passwordFocus ? "input-group-focus" : "")}>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="password"
                            data-testid="password-input"
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="placeholder:text-[#767676] placeholder:opacity-100"
                            value={password}
                            onChange={handleChange}
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                          />
                        </InputGroup>
                      </div>
                      { badCredentialsError() }
                    </CardBody>
                    <CardFooter>
                      <Button block size="lg" className="mb-3 btn-round bg-green-700" type="submit" data-testid="login-button">
                        Get Started
                      </Button>
                    </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
};

function mapState(state) {
  const { loggedIn, needMFA, error } = state.authentication;
  return { loggedIn, needMFA, error };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };